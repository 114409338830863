import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TradingViewWidget from './TradingViewWidget';
import './App.css';
import { Container, Navbar, Table, Card, Row, Col, Spinner } from 'react-bootstrap';

function App() {
  const [topContracts, setTopContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopContracts = async () => {
      try {
        const response = await axios.get('https://gpt.cafe/api/top-contracts');
        setTopContracts(response.data.slice(0, 10)); // Fetch the top 10 contracts
      } catch (err) {
        setError('Failed to fetch top contracts.');
      } finally {
        setLoading(false);
      }
    };

    fetchTopContracts();
  }, []);

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (error) {
    return <Container className="text-center mt-5">{error}</Container>;
  }

  return (
    <Container>
      {/* Navbar */}
      <Navbar bg="dark" variant="dark" className="mb-4">
        <Navbar.Brand href="#">Trading Dashboard</Navbar.Brand>
      </Navbar>

      {/* Top 10 Contracts Table */}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Contract</th>
            <th>% Change/Hour</th>
            <th>4hr Range %</th>
            <th>24hr Range %</th>
            <th>Current Price</th>
          </tr>
        </thead>
        <tbody>
          {topContracts.map((contract, index) => (
            <tr key={index}>
              <td>{contract.symbol.replace('USDT', '')}</td>
              <td>{contract.hourly_change ? contract.hourly_change.toFixed(2) : 'N/A'}%</td>
              <td>{contract.range_4hr ? contract.range_4hr.toFixed(2) : 'N/A'}%</td>
              <td>{contract.range_24hr ? contract.range_24hr.toFixed(2) : 'N/A'}%</td>
              <td>{contract.current_price ? contract.current_price.toFixed(6) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Cards for Contract Details */}
      <Row>
        {topContracts.map((contract, index) => (
          <Col md={6} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{contract.symbol.replace('USDT', '')}</Card.Title>
                <Card.Text>
                  <strong>% Change/Hour:</strong> {contract.hourly_change ? contract.hourly_change.toFixed(2) : 'N/A'}%<br />
                  <strong>4hr Range %:</strong> {contract.range_4hr ? contract.range_4hr.toFixed(2) : 'N/A'}%<br />
                  <strong>24hr Range %:</strong> {contract.range_24hr ? contract.range_24hr.toFixed(2) : 'N/A'}%<br />
                  <strong>Current Price:</strong> {contract.current_price ? contract.current_price.toFixed(6) : 'N/A'}
                </Card.Text>
                <div className="widget-container">
                  <TradingViewWidget symbol={contract.symbol.replace('USDT', '')} />
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default App;
