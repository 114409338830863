import React, { useEffect, useRef, memo } from 'react';

const TradingViewWidget = memo(({ symbol }) => {
  const container = useRef();

  useEffect(() => {
    const formattedSymbol = symbol.endsWith('USDT') ? `${symbol}.P` : `${symbol}USDT.P`;

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol: formattedSymbol,  // Use correct symbol format for Bybit
      interval: '60',           // 60-minute interval
      timezone: 'America/New_York',  // Set timezone to EST
      theme: 'light',           // Set theme to white
      style: '1',
      locale: 'en',
      allow_symbol_change: false,
      hide_top_toolbar: true,   // Hide top toolbar
      hide_legend: true,        // Hide the legend (OHLC values)
      calendar: false,
      support_host: 'https://www.tradingview.com',
    });

    // Clear previous content to avoid multiple widget instances
    container.current.innerHTML = '';
    container.current.appendChild(script);
  }, [symbol]);

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: '300px', width: '100%' }}>
      <div className="tradingview-widget-container__widget" style={{ height: '100%', width: '100%' }}></div>
    </div>
  );
});

export default TradingViewWidget;
